import styles from './LocalSectionSand.css'
import cssStyleContext from '/cssGlobal/style-context.css'

export function LocalSectionSand({ children, dataX, layoutClassName = undefined }) {
  return (
    <section
      data-x={dataX}
      className={cx(styles.component, layoutClassName)}
      data-style-context={cssStyleContext.contextSand}
    >
      {children}
    </section>
  )
}
